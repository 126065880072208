<template>
  <div class="edit-table">
    <vxe-toolbar v-if="code !=='view'">
      <template v-slot:buttons>
        <vxe-button @click="addTable()">新增</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table  ref="fineTable" :data="subjectList" height="300px">
      <vxe-table-column min-width="100" field="ext1" title="活动明细名称"></vxe-table-column>
      <vxe-table-column min-width="120" field="ext65" title="活动明细行状态">
        <template #default="{ row }">
            <span v-if="row.ext65==='create'">待提交</span>
            <span v-if="row.ext65==='approving'">审批中</span>
            <span v-if="row.ext65==='approved'">审批通过</span>
            <span v-if="row.ext65==='rejected'">审批驳回</span>
            <span v-if="row.ext65==='interrupt'">流程追回</span>
            <span v-if="row.ext65==='closing'">发起活动关闭</span>
            <span v-if="row.ext65==='closed'">活动关闭</span>
        </template>
      </vxe-table-column>
      <vxe-table-column min-width="100" field="actDetailCode" title="活动明细编码"></vxe-table-column>
      <vxe-table-column min-width="100" field="ext10" title="是否合规" >
         <template #default="{ row }">
            <span v-if="row.ext10==='Y'">是</span>
            <span v-if="row.ext10==='N'">否</span>
        </template>
      </vxe-table-column>
      <vxe-table-column min-width="100" field="categoriesCode" title="活动大类编码"></vxe-table-column>
      <vxe-table-column min-width="100" field="categoriesName" title="活动大类名称"></vxe-table-column>
      <vxe-table-column min-width="100" field="fineCode" title="活动细类编码"></vxe-table-column>
      <vxe-table-column min-width="100" field="fineName" title="活动细类名称"></vxe-table-column>
      <vxe-table-column min-width="130" field="ext3" title="公司主体"></vxe-table-column>
       <vxe-table-column min-width="130" field="ext23" title="未核报金额(元)"></vxe-table-column>
      <vxe-column v-if="code !=='view'" title="操作" width="80">
        <template #default="{ row }">
          <template >
            <span @click="deletRow(row)" style="color:red; cursor: pointer;">删除</span>
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '@/found/utils/request';

import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'BudgetSubject',
  components: {
    SelectConfig,
  },
  props: {
    code: String,
    actCode: String,
    saveRef: Function,
    value: Array,
    disabled: Boolean,
    startTime: String,
    endTime: String,
    isControl: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value() {
      this.subjectList = this.value;
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
    };
  },

  methods: {
    // 删除行
    deletRow(row) {
      console.log(this.subjectList);
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.fineTable.remove(row);
        this.subjectList.forEach((item, index) => {
          if (item.id === row.id) {
            this.subjectList.splice(index, 1);
          }
        });
        this.$emit('change', this.subjectList);
        this.$message({ type: 'success', message: '删除成功!' });
      });
    },

    // 清空列表
    deleteTable() {
      this.$refs.fineTable.remove();
      this.subjectList = [];
      this.$emit('change', this.subjectList);
    },

    // 新增
    addTable() {
      const params = {
        functionCode: 'act_detail_list',
        data: this.subjectList,
        idKey: 'id',
        paramData: {
          actCode: this.actCode,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    onGetSelect(val) {
      const data = this.getReset(val, [], 'actDetailCode');
      this.subjectList = data;
      this.$emit('change', data);
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;

}
</style>
